body.popup-open {
    overflow: hidden;
}

.popup {
    height: 100vh;
    width: 100%;
    background-color: #575757c7;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9999;
    margin: 0px !important;
    display: flex;

    .popup-container {
        max-width: 910px;
        height: auto;
        background-color: #f3f3f3;
        text-align: center;
        box-shadow: 1px 1px 5px 2px #717171;
        overflow: hidden;
        padding: 0px;
        padding-bottom: 30px;
        width: 80%;
        border-radius: 20px;
        position: absolute;
        left: calc();
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;

        .popup-close-btn {
            display: flex;
            top: 15px;
            right: 10px;
            background: #80808000;
            border: 0px;
            font-size: 20px;
            font-family: cursive;
            position: absolute;

            img {
                width: 15px;
                height: 15px;
            }
        }

        .popup-header {
            height: 50px;
            align-content: center;
            font-size: 20px;
            font-weight: bolder;
            background: var(--accent-color);
            color: #333;
            font-weight: 100;

            span {
                font-weight: bold;
            }
        }

        .popup-body {
            padding-top: 10px;
        }
    }
}

.bet-container {
    padding: 10px 30px 10px 30px;
    max-height: 600px;

    .bet-table {
        overflow-y: scroll;
        max-height: 500px;
    }

    .bet-amount {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 14px;

        label,
        span,
        input,
        button {
            margin: 5px;
        }

        input {
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 10px;
            padding: 0 15px;
            font-size: 18px;
            color: #333;
            width: 100px;
            background-color: #fff;
            appearance: none;
            -webkit-appearance: none;
        }

        button {
            border-radius: 5px;
            background: #2196f3;
            height: 40px;
            border: 0px;
            padding: 10px;
            color: white;
        }
    }
}

.race-details {
    box-shadow: 0px 0px 0px 0px grey;
    background: white;

    th {
        font-size: 12px;
        min-width: 60px;
    }

    th.slider-header {
        min-width: 180px;
    }

    td {
        font-size: 12px;
        .profit-range {
            width: 60%;
        }
        .profit-range-amount {
            width: 25%;
            height: 26px;
            border: 1px solid #ccc;
            border-radius: 0px;
            padding: 0 3px;
            font-size: 14px;
            color: #333;
            background-color: #fff;
            appearance: none;
            -webkit-appearance: none;
            margin-left: 5px;
        }
    }
}

@media (max-width: 767px) {
    .race-details {
        background: none;

        .hidden {
            display: none;
        }

        tr {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            position: static;
            height: auto;
            padding: 10px;
        }

        td {
            display: flex;
            flex: 1 1 auto;
            position: relative;
            height: 30px;
            min-width: 100px;
            align-items: center;
            justify-content: center;
            border-radius: 0px;
            /* border-bottom: 1px #ededed solid; */
            margin: 5px;
            padding: 15px;
            background: #f3f3f3;
        }

        td:before {
            content: attr(data-label);
            font-weight: bolder;
            margin-right: 10px;
        }

        td.card-name {
            width: 100%;
            font-size: 15px;
            background: none;
            border-bottom: 2px #ebebeb solid;
            font-weight: bold;
        }

        .profit-range {
            min-width: 100px;
            max-width: 150px;
        }
        .profit-range-amount {
            width: 60px;
        }
    }

    .tools {
        tr {
            display: flex;
            overflow: hidden;
            flex-wrap: wrap;
            padding: 20px;
        }
        tr.expanded {
            height: 100%;
            padding-bottom: 90px;
        }

        td.card-venue {
            display: grid;
            top: 25px;
            width: 93%;
            margin-left: 0px;
            padding-left: 0px;
            color: var(--card-font-color);
            font-size: 15px;
            border-bottom: 1px solid #6d6d6d;
            height: 30px;
        }

        td.card-details {
            top: 65px;
            position: relative;
            display: flow-root;
            left: 0px;
            width: 130px;
            padding-top: 3px;
            flex: 1 1 auto;
            margin: 2px;
            border-radius: 5px;
            text-align: center;
            align-content: center;
            height: 40px;
            background: #2196f321;
        }

        td.race-disabled {
            background: #c7c7c7;
        }
    }
}
