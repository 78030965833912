.menubar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 10%;
    color: white;
    transition: all 0.2s ease;
}

.menu-icon {
    font-size: 1.5em;
    margin-left: 5%;
}

.menu-icon > a {
    text-decoration: none;
    color: white;
    i {
        display: none;
    }

    img {
        width: 50px;
        height: 50px;
    }
}

.menu-menu {
    display: flex;
    gap: 20px;
}

.menu-item {
    cursor: pointer;
    padding: 10px;
    color: white;
    text-decoration: none;
}

.menu-hover,
.menu-item:hover {
    background: var(--accent-color);
    color: black;
    text-decoration: none;
    font-style: normal;
    transition: 0.2s ease-in-out;
    border-radius: 10px;
}

/* Mobile view */
@media (max-width: 768px) {
    .menubar {
        position: fixed;
        width: 100%;
        bottom: 0px;
        height: 70px;
        z-index: 899;
        /* border-radius: 35px 35px 0px 0px; */
    }

    .menu-icon {
        margin: 0px;
        position: fixed;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #333;
        padding: 10px;
        border-radius: 100px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--accent-color);
    }

    .menu-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .menu-item {
        width: 80px;
        padding: 10px 0px;
        text-align: center;
    }

    .menu-hover,
    .menu-item:hover {
        border-radius: 10px 10px 0px 0px;
        height: 90px;
        text-align: center;
        align-content: center;
        font-size: 14px;
        font-weight: bold;
        padding: 5px;
    }

    .menu-home {
        display: none;
    }

    .menu-tools {
        display: none;
    }

    .menu-icon > a > i {
        display: block;
    }

    .menu-icon > a > img {
        display: none;
    }

    .mobile-hidden {
        display: none;
    }
}
