body {
    font-family: Arial, Helvetica, sans-serif;
}

.mobile-container {
    max-width: 480px;
    margin: auto;
    background-color: #555;
    height: 500px;
    color: white;
    border-radius: 10px;
}

.topnav {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #333;
}

.topnav #myLinks {
    display: none;
}

.topnav a {
    float: left;
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    text-align: right;
    text-indent: 100;
}

.topnav a.icon {
    float: right;
}

.topnav a:hover {
    background-color: #f5f5f5;
    color: black;
}
.active {
    background-color: #4caf50;
    color: white;
}
.vertical-center {
    text-align: center !important;
}
.button {
    background-color: #e7e7e7; /* Gray */
    border: none;
    color: black;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
}
.button:hover {
    background-color: #333;
    color: white;
}

.subHeader {
    color: #60544f !important;
    text-align: center !important;
}
