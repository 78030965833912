.next-container {
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: center;
    min-height: auto;
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .next-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  .next-container h3 {
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;
  }
  
  .next-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .next-options label {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
  }
  
  .next-options input {
    margin-right: 10px;
  }
  
  .next-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .next-button:hover {
    background-color: #0056b3;
  }
  

.nextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 75px);
  padding-bottom: 15%;
}


.nextForm {
  width: 550px;
  height: auto;
  max-height: 600px;
  padding: 20px;
  background-color: #fbfbfb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  text-align: center;
}




.next-title {
  display: flex;
  background: var(--primary-color);
  border-radius: 30px 30px 0px 0px;
  margin: -20px -20px 0px -20px;
  height: 70px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

