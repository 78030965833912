h1 {
    color: #60544f; /* #e7e2cf   #78ab46; */
}

footer {
    position: fixed;
    height: auto;
    bottom: 0;
    width: 100%;
    background-color: rgb(38, 39, 44);
    color: #fff;
    z-index: 1001;
}

footer a {
    color: #f1c050;
}

footer p {
    padding: 15px;
}

.menu {
    float: right;
    margin-right: 15%;
    margin-top: 11px;
}
.menu a {
    float: left;
    display: block;
}

.topnav {
    overflow: hidden;
    background-color: #333;
    position: relative;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 10px 16px;
    text-decoration: none;
    font-size: 17px;
    transition: 0.2s;
}

.topnav a:hover {
    background-color: #fff;
    color: black;
    border-radius: 15px 15px 0px 0px;
}
.topnav a.icon {
    float: right;
    display: none;
}

/* 
.container {
    padding-right: initial !important;
    padding-left: initial !important;
}
*/

/* Overwrite bootstrap css */
.navbar {
    padding: initial !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 1001 !important;
}

.navbar-brand {
    margin-left: 6% !important;
}

@media {
    .container {
        max-width: inherit !important;
        padding-right: initial !important;
        padding-left: initial !important;
    }
}

@media screen and (max-width: 600px) {
    .topnav a {
        display: none;
    }
    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav {
        position: fixed !important;
        bottom: 0px;
        display: flex;
        justify-content: center;
    }
    .menu.responsive {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-content: center;
    }
    .menu.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
    }
    .menu.responsive a {
        float: none;
        display: block;
        text-align: left;

        float: none;
        display: flex;
        text-align: left;
        font-size: 12px;
        width: auto;
        justify-content: center;
    }
    footer {
        position: relative;
    }
}

.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8) url("../images/teal_loader.gif") 50%
        50% no-repeat;
}
/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
body.loading .modal {
    overflow: hidden;
}

/* Anytime the body has the loading class, our
   modal element will be visible */
body.loading .modal {
    display: block;
}

.ctext {
    /*font-size: 40px;*/
    color: #e7e2cf;
    text-align: center;
}
