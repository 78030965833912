.subscription-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .subscription-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .subscription-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .subscription-options label {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
  }
  
  .subscription-options input {
    margin-right: 10px;
  }
  
  .subscription-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .subscription-button:hover {
    background-color: #0056b3;
  }
  
/* Scoped CSS for the modal */
.my-modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

.my-modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.subscriptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 75px);
  padding-bottom: 15%;
}

.subscriptionForm {
  width: 550px;
  height: auto;
  max-height: 600px;
  padding: 20px;
  background-color: #fbfbfb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  text-align: center;
}

.successForm {
  width: auto;
  height: auto;
  max-height: 600px;
  padding: 20px;
  background-color: #fbfbfb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  text-align: center;
}

/* Button Styles */
.action-button {
  width: calc(100% - 20px);
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  background-color: #28a745;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.cancel-button {
  width: calc(100% - 20px);
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  background-color: #ded335;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #218838;
}

.cancel-button:hover {
  background-color: #882121;
}

.subscription-title {
  display: flex;
  background: var(--primary-color);
  border-radius: 30px 30px 0px 0px;
  margin: -20px -20px 0px -20px;
  height: 70px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

