body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* Global Webkit scrollbar styling */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
    height: 12px; /* Height of the scrollbar for horizontal scrollbars */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background: #888; /* Background of the scrollbar thumb */
    border-radius: 6px; /* Roundness of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Background of the scrollbar thumb on hover */
}

/* Global Firefox scrollbar styling */
* {
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #888888 #f1f1f1; /* Color of the thumb and track */
}
