.mobile-footer {
    display: none;
}

@media (max-width: 768px) {
    footer {
        .container {
            display: none;
        }
    }

    .mobile-footer {
        display: block;
        width: auto;
        height: 50px;
        position: fixed;
        z-index: 999;
        bottom: 11px;
        left: 4%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        i {
            cursor: pointer;
        }
        ul {
            height: 0px;
            width: 0px;
            overflow: hidden;
            display: none;
        }
        ul.expanded {
            display: block;
            height: auto;
            width: auto;
            display: initial;
            background: #333333;
            position: fixed;
            left: 4%;
            bottom: 60px;
            border-radius: 20px;
            list-style-type: none;
            padding: 10px 10px;
            opacity: 0.95;
            transition: all 0.1s ease-in-out;

            a {
                text-decoration: none;
            }

            li {
                font-size: 14px;
                margin-bottom: 5px;
                border-bottom: 1px #4f4f4f solid;
                padding-left: 10px;
                padding-right: 10px;
                height: 35px;
                display: flex;
                align-items: center;
                min-width: 150px;
                cursor: pointer;
                color: white;
            }

            li:hover {
                background-color: #efba1c;
            }
        }
    }
}
