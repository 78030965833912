:root {
    --primary-color: #333;
    --accent-color: #e1af1b;
    --accent-color-2: green;
    --hover-color: #f0d070;
    --hover-color-2: rgb(29, 202, 29);
    --highlight-color: #ffcc00;
    --error-color: rgb(202, 92, 92);

    --card-font-color: #3b3b3b;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.table-header {
    font-size: 25px;
    font-weight: 600;
    margin-top: 30px;
    width: 40%;
    margin-bottom: 15px;
    min-height: 30px;
}

.myTabs {
    margin-left: auto;
    margin-right: auto;
    opacity: 0.7;
    width: 75%;
    overflow: hidden;
    box-shadow: 1px 1px 4px grey;
    border-radius: 10px;
}

.myTabsDetail {
    margin-left: auto;
    margin-right: auto;
    opacity: 0.7;
    width: 100%;
    overflow: hidden;
    box-shadow: 1px 1px 4px grey;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
}

.myTabs thead {
    background-color: #dbdbdb;
}

.myTabs th {
    width: 10%;
    color: rgb(14, 14, 14);
    height: 30px;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 14px;
    text-transform: capitalize;
    text-align: left;
    padding-left: 10px;
}

.myTabs td {
    min-width: 18px;
    width: 18px;
    padding: 0px 10px;
    color: rgb(14, 14, 14);
    height: 25px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    text-align: left;
}

.myTabs {
    tr {
        border-bottom: 1px solid #b9b9b9;
        /* height: 35px; */
    }

    tbody {
        tr:hover {
            background-color: #2196f314;
        }
    }

    td.card-hidden,
    th.card-hidden {
        display: none;
    }
    td.card-type,
    th.card-type {
        /* display: none; */
    }

    td.card-type {
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 10px;
            border-radius: 10px;
            height: 20px;
            font-size: 12px;
            margin: 2px;
            border: 1px white solid;
            /* width: 65px; */
        }

        .card-rated {
            background-color: #ff4e00;
            color: white;
        }

        .card-value {
            background-color: #efba1c;
        }

        .card-2way {
            background-color: #2196f3;
            color: white;
        }
        .card-Slag {
            background-color: #2196f3;
            color: white;
        }

        .card-best {
            background-color: #efba1c;
        }
    }

    td.race-disabled {
        background: #c7c7c7;
        /* text-decoration: line-through; */
        cursor: pointer;
        /* pointer-events: none; */
    }

    th.card-tool-race,
    td.card-tool-race {
        text-align: center;
    }

    td.card-tool-race {
        transition: all 0.4s;
        cursor: pointer;
    }

    td.card-tool-race:hover {
        background-color: #7272e9;
        color: white;
    }
}

/* .myTabs tr.win {
    background-color: #e8f5f0;   Pastel Green  
} */

/* td.card-win {
    font-weight: 900;
} */

.myTabs.tools {
    th {
        width: auto;
    }

    td {
        height: 35px;
        font-size: 14px;
    }
}

.myTabs tr.abnd {
    text-decoration: line-through;
    background-color: #cdcaca !important;
}
.myTabs tr.scr {
    text-decoration: line-through;
    background-color: #dbc5c5 !important;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 50% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.myTabs tr.scr {
    background-color: #dbc5c5 !important;
    text-decoration: line-through;
}

.totdText {
    background-color: #ffffff;
    opacity: 0.8;
    color: #000000;
}

.section-body {
    background-color: #f5f5f5;
    padding-bottom: 180px;
    min-height: calc(100vh - 130px);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Css for new layout */

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 20px;
}

.slider.round:before {
    border-radius: 50%;
}

/* tab slider */
.tab-switch {
    position: relative;
    display: inline-block;
    width: 140px;
    height: 40px;
}

.tab-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.tab-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2196f3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    display: flex;

    div {
        width: 50%;
        font-size: 13px;
        color: white;
        align-content: center;
    }
}

.tab-slider:before {
    position: absolute;
    content: "";
    height: 34px;
    width: 64px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
}

input:checked + .tab-slider {
    background-color: #2196f3;
}

input:focus + .tab-slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .tab-slider:before {
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
}

/* Rounded sliders */
.tab-slider.round {
    border-radius: 10px;
}

.tab-slider.round:before {
    border-radius: 8px;
}

/*  */

.filter-container {
    display: grid;
    width: 75%;
    /* justify-content: center; */
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.filter-section {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: -70px;
    margin-left: 274px;
}

.filter-section.center {
    justify-content: center;
    margin-left: 0px;
}

.filter-dropdown {
    height: 40px; /* Increased height for better touch target */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 10px; /* Adjusted border radius for better aesthetics */
    padding: 0 15px; /* Increased padding */
    font-size: 18px; /* Increased font size */
    color: #333; /* Darker color for better readability */
    margin-right: 10px;
    width: fit-content; /* Responsive width */
    min-width: 180px;
    max-width: 250px; /* Max width for larger screens */
    background-color: #fff; /* Background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    appearance: none; /* Remove default arrow in some browsers */
    -webkit-appearance: none; /* Remove default arrow in some browsers (Safari) */
}

.filter-dropdown:focus {
    border-color: #007bff; /* Border color on focus */
    outline: none; /* Remove default outline */
}

.filter-dropdown option {
    padding: 10px; /* Option padding for better spacing */
    font-size: 16px; /* Option font size */
}

.filter-checkbox {
}

.filter-checkbox-group {
}

.filter-group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-radio-group {
    width: auto;
    display: flex;
    margin-right: 20px;
}

.filter-checkbox-group {
    display: flex;
}

.filter-radio {
    input[type="radio"] {
        width: 20px;
        height: 20px;
    }
}

label {
    margin-bottom: 0px !important;
}

.switch-label {
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    color: #6d6d6d;
}

.topnav {
    overflow: hidden;
    background-color: var(--primary-color); /* Adjust as necessary */
}

.topnav a {
    float: left;
    display: block;
    color: #f0d070; /* var(--accent-color);  Adjust as necessary */
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.topnav a.icon {
    display: none;
}

@media screen and (max-width: 1336px) {
    .table-header {
        width: 100%;
        height: auto;
        min-height: 0px;
    }
    .myTabs {
        width: 85%;
    }
    .section-body {
        padding-top: 5px;
    }
    .filter-container {
        display: block;
    }

    .filter-section {
        margin-top: 0px;
        justify-content: center;
        margin-left: 0px;
        margin-bottom: 20px;
    }
}

.nav-arrow {
    cursor: pointer;
    background-color: #828181;
    color: white;
    border: none;
    padding: 10px;
    margin: 5px;
    font-size: 16px;
    z-index: 10; /* Ensure the buttons are on top */
    position: relative; /* Ensure z-index works */
}

.nav-arrow:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

@media (max-width: 767px) {
    .section-body {
        margin-top: -30px;
        padding-top: 30px;
        min-height: 100vh;
    }

    .filter-section {
        display: block;
    }

    .filter-dropdown {
        width: 100%;
        margin-bottom: 15px;
        max-width: 330px;
    }

    .filter-group.tools {
        display: grid;
        grid-template-areas: "radio-group" "checkbox-group";
        grid-template-columns: auto;
        grid-template-rows: 50px 50px;
    }

    .filter-radio-group {
        grid-area: radio-group;
    }

    .filter-checkbox-group {
        grid-area: checkbox-group;
        display: block;
    }

    .filter-checkbox {
        display: inline;
    }

    .myTabs {
        border-collapse: collapse;
        /* width: 100%; */
        box-shadow: 0px 0px;
        border-radius: 0px;
        width: 90%;
    }

    .myTabs thead {
        display: none; /* Hide headers */
    }

    .myTabs tr.win {
        background-color: #a8e6cf; /* Pastel Green */
        font-weight: bold;
    }

    .myTabs tr.lose {
        background-color: #ffaaa5; /* Pastel Red */
    }

    .myTabs tr.place {
        background-color: #ffecb3; /* Pastel Yellow */
    }

    .myTabs tr.abnd {
        background-color: #cdcaca !important;
    }

    .myTabs tr.scr {
        background-color: #dbc5c5 !important;
        text-decoration: line-through;
    }

    .myTabs tr {
        margin-bottom: 10px;
        display: block;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
        background-color: white;
        height: 80px;
        border-radius: 10px;
        position: relative;
        cursor: pointer;
        transition: all 0.2s ease-in;
        overflow: hidden;
    }

    .myTabs tr.expanded {
        height: 190px;
    }

    /* new Card Layout css */
    .myTabs {
        td {
            position: absolute;
            text-align: center;
            display: none;
            text-align: left;
        }

        td.card-time {
            display: grid;
            top: 0px;
            width: 75px;
            height: 25px;
            margin-left: 0px;
            background: #357ae8;
            border-radius: 5px 0px 0px 0px;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 11px;
            font-weight: bolder;
        }

        td.card-race {
            display: flex;
            top: 35px;
            width: 75px;
            margin-left: 0px;
            font-size: 25px;
            color: var(--card-font-color);
            align-items: center;
            justify-content: center;
            font-weight: 100;
        }

        td.card-race::before {
            display: flex;
            width: 0px;
            font-size: 25px;
            color: var(--card-font-color);
            align-items: center;
            justify-content: center;
            font-weight: 100;
            content: "R";
            margin-right: 10px;
        }

        td.card-tip {
            display: flex;
            top: 53px;
            margin-left: 68px;
            width: auto;
            min-width: 35px;
            height: 20px;
            color: var(--primary-color);
            font-size: 16px;
            background: white;
            border-radius: 20px;
            border: 1px solid var(--primary-color);
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 0px 7px;
            font-weight: bold;
        }

        td.card-tip::before {
            content: "# ";
        }

        td.card-venue {
            display: grid;
            top: 28px;
            width: 60%;
            margin-left: 68px;
            padding-left: 0px;
            color: var(--card-font-color);
            font-size: 16px;
            border-bottom: 1px solid #6d6d6d;
            height: 20px;
        }

        td.card-horse {
            display: block;
            top: 52px;
            width: 100%;
            margin-left: 105px;
            color: var(--primary-color);
            font-size: 16px;
            font-weight: bold;
        }

        td.card-win {
            display: grid;
            top: 7px;
            width: auto;
            justify-content: center;
            color: var(--card-font-color);
            font-size: 18px;
            text-align: right;
            height: auto;
            right: 5px;
            font-weight: bold;
        }

        td.card-win::before {
            display: grid;
            color: var(--card-font-color);
            font-size: 11px;
            content: attr(data-label);
            height: 10px;
            font-weight: 100;
        }

        td.card-place {
            display: grid;
            top: 42px;
            width: auto;
            justify-content: center;
            color: var(--card-font-color);
            font-size: 18px;
            text-align: right;
            height: auto;
            right: 5px;
            font-weight: bold;
        }
        td.card-place::before {
            display: grid;
            color: var(--card-font-color);
            font-size: 11px;
            content: attr(data-label);
            height: 10px;
            font-weight: 100;
        }

        td.card-hidden {
            display: none;
        }

        td.card-details {
            top: 90px;
            position: relative;
            display: block;
            left: 75px;
            border-top: 1px #c7c7c7 solid;
            width: 50%;
            padding-top: 3px;
        }

        td.card-details::before {
            margin-right: 10px;
            content: attr(data-label);
            font-weight: bolder;
        }

        td.card-type {
            display: flex;
            top: 0px;
            width: auto;
            left: 77px;
            justify-content: left;
            align-items: center;
            padding: 0px;
            min-width: 60px;

            div {
                display: grid;
                padding: 0px 10px;
                height: 25px;
                justify-content: center;
                align-items: center;
                border-radius: 0px;
                border: 0px;
                margin: 0px 2px 0px 0px;
            }
        }

        tr.expanded-row {
            height: auto;
        }

        td.expanded-cell {
            display: block;
            height: auto;
            position: relative;
            width: auto;
            padding: 0px;
            padding: 0px 0px 0px 0px;
        }
    }

    .myTabsDetail {
        box-shadow: none;
        border-radius: 0px;
        width: 100%;
        thead {
            display: table-header-group;
            tr {
                background: rgb(199, 199, 199);
            }
        }
        tr {
            height: 30px;
            border-radius: 0px;
            box-shadow: none;
            display: table-row;
        }

        td {
            position: relative;
            display: table-cell;
        }

        th.hidden,
        td.hidden {
            display: none;
        }
    }
}

@media (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }
    .topnav a.icon {
        float: right;
        display: block;
    }

    .topnav.responsive {
        position: relative;
    }
    .topnav.responsive a.icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}
