.merch-container {
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: center;
    min-height: auto;
    background-color: #f0f0f0;
    padding: 20px;
}

.merch-item {
    display: flex;
    flex-direction: row;
    background-color: #fbfbfb;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 20px;
    margin-bottom: 20px;
    align-items: center;
}

.merch-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.merch-image {
    width: 150px;
    height: auto;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 15px;
}
.merch-header {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.merch-header h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}
.merch-right {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.merch-right h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.merch-right p {
    font-size: 16px;
    color: #666;
    text-align: left;
    margin-bottom: 20px;
}

.merch-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.merch-option {
    margin-bottom: 10px;
}

.merch-option label {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
}

.merch-option select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    color: #333;
}

.merch-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    align-self: flex-start;
}

.merch-button:hover {
    background-color: #0056b3;
}

.out-of-stock {
    color: red;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    text-align: left;
}

.modal-images img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .merch-item {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .merch-header {
        order: -1; /* Move the title and description above the image */
        padding-left: 0;
        margin-top: 20px;
    }

    .merch-right {
        padding-left: 0;
        margin-top: 20px;
    }

    .merch-image {
        max-width: 100%;
    }
    .merch-button {
        padding: 10px 20px;
        font-size: 18px;
        background-color: #007bff;
        border: none;
        color: #fff;
        cursor: pointer;
        border-radius: 5px;
        margin-top: 10px;
        align-self: flex-start;
    }
}