.signInContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f0f0f0;
    padding: 20px;
}

.signInForm {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    width: 100%;
}

.signInForm input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.signInForm button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #28a745;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.signInForm button:hover {
    background-color: #218838;
}

/* New Layout */

.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 75px);
}
.signin-form {
    width: 350px;
    height: auto;
    max-height: 600px;
    padding: 20px;
    background-color: #fbfbfb;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    text-align: center;
}

.signin-title {
    display: flex;
    background: var(--primary-color);
    border-radius: 30px 30px 0px 0px;
    margin: -20px -20px 0px -20px;
    height: 70px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.signin-form h2 {
    color: white;
    font-size: 25px;
    font-family: system-ui;
    font-weight: lighter;
}
.signin-form input {
    width: calc(100% - 20px);
    padding: 10px 20px;
    margin: 10px 0;
    border: 1px solid #e3e3e3;
    border-radius: 30px;
    background: white;
    font-size: 14px;
    height: 50px;
}

.signin-form input:disabled {
    background-color: rgb(233, 233, 233);
}

.signin-form input:focus,
.signin-form button:focus {
    border-color: var(--highlight-color);
    box-shadow: 0 0 5px var(--highlight-color);
    outline: 0px solid var(--highlight-color);
    outline-offset: 0px;
}

.signin-form button {
    min-width: 120px;
    padding: 10px 20px;
    margin: 10px 10px;
    border: none;
    background-color: var(--accent-color);
    color: #ffffff;
    border-radius: 30px;
}
.signin-form button:hover {
    background-color: var(--hover-color);
}

.signin-logo {
    width: 120px;
    height: auto;
    margin-bottom: 20px;
}

.signout {
    display: block;
    i {
        width: 100%;
        font-size: 100px;
        color: rgb(214, 214, 214);
    }
}

.horizontal-line {
    width: calc(100% - 20px);
    border: 0.5px solid #f1f1f1;
    margin: 5px 0;
}

.signup-button {
    background-color: var(--accent-color-2) !important;
}
.signup-button:hover {
    background-color: var(--hover-color-2) !important;
}

.forgot-pass-div {
    color: var(--primary-color);
    font-size: 15px;
    cursor: pointer;
    margin: 10px;
}

.forgot-pass-div:hover {
    color: var(--accent-color);
}

.error-div {
    font-size: 12px;
    color: var(--error-color);
    text-align: center;
    padding: 0px 10px;
    font-weight: bold;
}

.message-div {
    font-size: 12px;
    color: var(--accent-color);
    text-align: center;
    padding: 0px 10px;
    font-weight: bold;
}
